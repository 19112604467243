<template>
  <div>
    <v-row justify="center" style="margin-bottom: 200px; margin-top: 150px">
      <v-card outlined width="400" height="220">
        <div
          style="
            width: 100%;
            background-color: rgb(90, 90, 90);
            height: 60px;
            top: 0;
            position: absolute;
          "
        >
          <p
            style="
              font-family: notosans_light;
              font-size: 20px;
              font-weight: bold;
              margin-top: 15px;
              margin-left: 20px;
              color: white;
            "
          >
            부가서비스 신청 현황
          </p>
        </div>
        <div
          style="
            margin-top: 90px;
            font-family: notosans_light;
            font-size: 23px;
            font-weight: bold;
          "
        >
          <v-row justify="center">
            <table style="width: 80%">
              <tr>
                <td style="padding: 10px">교과서 챗봇 서비스</td>
                <td>
                  <img
                    v-if="this.servicestatus.aiChatStatus == false"
                    style="margin-top: 8px"
                    width="30"
                    src="../assets/images/statusnocheck.png"
                    alt=""
                  />
                  <img
                    v-if="this.servicestatus.aiChatStatus == true"
                    style="margin-top: 8px"
                    width="30"
                    src="../assets/images/statuscheck.png"
                    alt=""
                  />
                </td>
              </tr>
              <tr>
                <td style="padding: 10px">자유 대화 챗봇 서비스</td>
                <td>
                  <img
                    v-if="this.servicestatus.freeChatStatus == false"
                    style="margin-top: 8px"
                    width="30"
                    src="../assets/images/statusnocheck.png"
                    alt=""
                  />
                  <img
                    v-if="this.servicestatus.freeChatStatus == true"
                    style="margin-top: 8px"
                    width="30"
                    src="../assets/images/statuscheck.png"
                    alt=""
                  />
                </td>
              </tr>
            </table>
          </v-row>
        </div>
      </v-card>
      <v-card
        outlined
        width="800"
        height="1050"
        style="
          margin-left: 10px;
          font-family: notosans_light;
          font-weight: bold;
        "
      >
        <div
          style="
            width: 100%;
            background-color: rgb(90, 90, 90);
            height: 60px;
            top: 0;
            position: absolute;
          "
        >
          <p
            style="
              font-size: 20px;
              margin-top: 15px;
              margin-left: 20px;
              color: white;
            "
          >
            부가서비스 이용
          </p>
        </div>
        <div
          style="
            margin-top: 90px;
            font-size: 19px;
            width: 100%;
            border-bottom: 1px solid rgb(220, 220, 220);
          "
        >
          <v-btn
            @click="openchangeinfo()"
            outlined
            style="
              padding: 10px;
              margin-left: 20px;
              font-size: 19px;
              font-famliy: notosans_light;
              font-weight: bold;
              border: none;
              margin-bottom: 20px;
              color: black;
            "
          >
            현재 학년/반 : {{ userinfo.grade }}학년 {{ userinfo.classNum }}반
          </v-btn>
          <v-btn
            @click="openchangeinfo()"
            outlined
            width="100"
            height="40"
            style="
              margin-bottom: 20px;
              font-weight: bold;
              background-color: #0c7ee5;
              color: white;
              font-size: 17px;
              margin-left: 40px;
            "
            >변경</v-btn
          >
        </div>
        <div>
          <div
            v-if="servicestatus.aiChatStatus == false"
            style="margin-top: 30px; margin-left: 30px; font-size: 22px"
          >
            <p>교과서 챗봇 서비스 신청</p>
            <p
              style="
                font-size: 15px;
                color: rgb(150, 150, 150);
                margin-top: -15px;
              "
            >
              교과서 기반의 어휘로 이루어진 챗봇 서비스를 이용할 수 있습니다.
            </p>
            <v-btn
              @click="applychat()"
              width="130"
              height="50"
              style="
                font-weight: bold;
                background-color: #0c7ee5;
                color: white;
                font-size: 19px;
              "
              outlined
              >신청하기</v-btn
            >
          </div>

          <div
            v-if="servicestatus.aiChatStatus == true"
            style="margin-top: 20px; margin-left: 30px; font-size: 22px"
          >
            <p>교과서 챗봇 서비스 이용 현황</p>
            <p
              style="
                font-size: 15px;
                color: rgb(150, 150, 150);
                margin-top: -15px;
              "
            >
              현재 교과서 챗봇 서비스 사용량을 파악할 수 있습니다.
            </p>
            <p style="font-size: 21px">현재 누적 API 사용량/부과 금액</p>
            <p style="font-size: 23px; margin-top: -10px; margin-left: 15px">
              {{ chatapiamount().amount }}회 사용 X 10원 (챗봇 1회 대화) =
              {{ chatapiamount().bill }}원
            </p>
            <p
              style="
                font-size: 17px;
                color: rgb(150, 150, 150);
                margin-left: 15px;
                margin-top: -10px;
              "
            >
              다음 결제일 : {{ servicestatus.aiPaymentDate }}
            </p>
            <v-btn
              @click="opendeletemodal('ai')"
              width="160"
              height="50"
              style="
                font-weight: bold;
                background-color: #fc5230;
                color: white;
                font-size: 19px;
              "
              outlined
              >서비스 해지</v-btn
            >
          </div>
          <div
            v-if="servicestatus.freeChatStatus == false"
            style="margin-top: 70px; margin-left: 30px; font-size: 22px"
          >
            <p>자유 대화 챗봇 서비스 신청</p>
            <p
              style="
                font-size: 15px;
                color: rgb(150, 150, 150);
                margin-top: -15px;
              "
            >
              초등학교 수준의 쉬운 어휘로 이루어진 자유 대화 챗봇 서비스를
              이용할 수 있습니다.
            </p>
            <v-btn
              @click="applyfree()"
              width="130"
              height="50"
              style="
                font-weight: bold;
                background-color: #0c7ee5;
                color: white;
                font-size: 19px;
              "
              outlined
              >신청하기</v-btn
            >
          </div>

          <div
            v-if="servicestatus.freeChatStatus == true"
            style="margin-top: 100px; margin-left: 30px; font-size: 22px"
          >
            <p>자유 챗봇 서비스 이용 현황</p>
            <p
              style="
                font-size: 15px;
                color: rgb(150, 150, 150);
                margin-top: -15px;
              "
            >
              현재 자유 챗봇 서비스 사용량을 파악할 수 있습니다.
            </p>
            <p style="font-size: 21px">현재 누적 API 사용량/부과 금액</p>
            <p style="font-size: 23px; margin-top: -10px; margin-left: 15px">
              {{ freeapiamount().amount }}회 사용 X 20원 (챗봇 1회 대화) =
              {{ freeapiamount().bill }}원
            </p>
            <p
              style="
                font-size: 17px;
                color: rgb(150, 150, 150);
                margin-left: 15px;
                margin-top: -10px;
              "
            >
              다음 결제일 : {{ servicestatus.freePaymentDate }}
            </p>
            <v-btn
              @click="opendeletemodal('free')"
              width="160"
              height="50"
              style="
                font-weight: bold;
                background-color: #fc5230;
                color: white;
                font-size: 19px;
              "
              outlined
              >서비스 해지</v-btn
            >
          </div>
        </div>
        <div>
          <div
            v-if="billStatus == false"
            style="
              padding: 20px;
              width: 100%;
              border-top: 1px solid rgb(200, 200, 200);
              margin-top: 50px;
            "
          >
            <p style="margin-top: 30px; margin-left: 20px; font-size: 18px">
              결제 수단 관리
            </p>
            <p
              style="
                margin-top: -12px;
                margin-left: 20px;
                color: rgb(150, 150, 150);
                font-size: 15px;
              "
            >
              결제 정보를 바탕으로 서비스 사용량만큼 과금됩니다.
            </p>
            <v-btn
              @click="billregister()"
              width="190"
              height="50"
              style="
                font-weight: bold;
                background-color: #0c7ee5;
                color: white;
                font-size: 19px;
                margin-left: 20px;
              "
              outlined
              >결제 수단 관리</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-row>
    <v-dialog persistent width="700" height="550" v-model="applymodal">
      <v-card width="700" height="550" outlined style="overflow: hidden">
        <div
          style="padding: 30px; font-family: notosans_light; font-weight: bold"
        >
          <p style="font-size: 30px">서비스 신청</p>
        </div>
        <div
          style="padding: 35px; font-family: notosans_light; font-weight: bold"
        >
          <p style="font-size: 19px">[서비스 신청시 안내]</p>
          <p style="font-size: 18px">
            1. 서비스 신청시 각 기능을 즉시 이용할 수 있습니다.
          </p>
          <p style="font-size: 18px">
            2. 신청하신 학년/반 기준으로 API 사용량이 측정되며, 각 학년/반 별로
            별도로 자동 결제가 수행됩니다.
          </p>
        </div>
        <v-row justify="center">
          <div>
            <p
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 25px;
                margin-right: 10px;
              "
            >
              서비스를 신청하시겠습니까?
            </p>
          </div>
        </v-row>
        <v-row style="margin-top: 30px" justify="center">
          <div style="margin-right: 10px">
            <v-btn
              @click="applyservice()"
              width="130"
              height="50"
              outlined
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 18px;
                background-color: #0c7ee5;
                color: white;
              "
              >확인</v-btn
            >
            <v-btn
              @click="cancelapply()"
              width="130"
              height="50"
              outlined
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 18px;
                margin-left: 40px;
                background-color: #fc5230;
                color: white;
              "
              >취소</v-btn
            >
          </div>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog persistent width="700" height="550" v-model="deletemodal">
      <v-card width="700" height="550" outlined style="overflow: hidden">
        <div
          style="padding: 30px; font-family: notosans_light; font-weight: bold"
        >
          <p style="font-size: 30px">서비스 해지</p>
        </div>
        <div
          style="padding: 35px; font-family: notosans_light; font-weight: bold"
        >
          <p style="font-size: 19px">[서비스 해지시 안내]</p>
          <p style="font-size: 18px">
            1. 서비스 해지시 현재 부과된 요금이 바로 결제됩니다.
          </p>
          <p style="font-size: 18px">
            2. 서비스 해지시 즉시 해당 부가 서비스가 사용 정지되며, 다시
            신청하시면 사용하실 수 있습니다.
          </p>
        </div>
        <v-row justify="center">
          <div>
            <p
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 25px;
                margin-right: 10px;
              "
            >
              서비스를 해지하시겠습니까?
            </p>
          </div>
        </v-row>
        <v-row style="margin-top: 30px" justify="center">
          <div style="margin-right: 10px">
            <v-btn
              @click="deleteservice()"
              width="130"
              height="50"
              outlined
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 18px;
                background-color: #0c7ee5;
                color: white;
              "
              >확인</v-btn
            >
            <v-btn
              @click="closedeletemodal()"
              width="130"
              height="50"
              outlined
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 18px;
                margin-left: 40px;
                background-color: #fc5230;
                color: white;
              "
              >취소</v-btn
            >
          </div>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="changeinfomodal" width="500" height="330">
      <v-card outlined width="500" height="330" style="overflow: hidden">
        <div
          style="
            padding: 50px;
            font-family: notosans_light;
            font-weight: bold;
            font-size: 23px;
          "
        >
          학년/반 변경
        </div>
        <v-row justify="center">
          <div style="width: 400px">
            <v-select
              style="font-family: notosans_light; font-weight: bold"
              outlined
              @change="test()"
              v-model="selectinfo"
              :items="classlist"
              :item-text="classlist.text"
              :item-value="classlist.value"
            ></v-select>
          </div>
        </v-row>
        <v-row style="margin-top: 30px" justify="center">
          <div style="margin-right: 10px">
            <v-btn
              @click="changeclass()"
              width="130"
              height="50"
              outlined
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 18px;
                background-color: #0c7ee5;
                color: white;
              "
              >확인</v-btn
            >
            <v-btn
              @click="cancelchangeinfo()"
              width="130"
              height="50"
              outlined
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 18px;
                margin-left: 60px;
                background-color: #fc5230;
                color: white;
              "
              >취소</v-btn
            >
          </div>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chatStatus: true,
      freeStatus: false,
      billStatus: false,
      billregistermodal: false,
      servicestatus: {},
      userinfo: {},
      aiusage: 0,
      freeusage: 0,
      cardinfo: {},
      deletemodal: false,
      type: "",
      changeinfomodal: false,
      classlist: [],
      selectinfo: {},
      applymodal: false,
      applytype: "",
    };
  },
  async created() {
    this.userinfo = await this.$store.dispatch("USER_INFO");
    this.selectinfo = {
      publisher: this.userinfo.publisher,
      grade: this.userinfo.grade,
      classNum: this.userinfo.classNum,
    };
    this.cardinfo = await this.$store.dispatch("CARD_INFO");
    this.servicestatus = await this.$store.dispatch("SERVICE_STATUS");

    if (this.servicestatus.aiChatStatus) {
      // const data = {
      //   type: "ai",
      //   grade: this.userinfo.grade,
      //   classNum: this.userinfo.classNum,
      // };
      // this.aiusage = await this.$store.dispatch("SERVICE_USAGE", data);

      const data = {
        type: "ai",
      };

      const res = await this.$store.dispatch("GET_COUNT", data);

      const keys = Object.getOwnPropertyNames(res);
      let sum = 0;
      for (let i = 0; i < keys.length; i++) {
        sum += res[keys[i]];
      }

      this.aiusage = sum;
    }

    if (this.servicestatus.freeChatStatus) {
      // const data = {
      //   type: "free",
      //   grade: this.userinfo.grade,
      //   classNum: this.userinfo.classNum,
      // };
      // this.freeusage = await this.$store.dispatch("SERVICE_USAGE", data);

      const data = {
        type: "free",
      };

      const res = await this.$store.dispatch("GET_COUNT", data);

      const keys = Object.getOwnPropertyNames(res);
      let sum = 0;
      for (let i = 0; i < keys.length; i++) {
        sum += res[keys[i]];
      }

      this.freeusage = sum;
    }

    this.setclasslist();
  },
  methods: {
    test() {
      console.log(this.selectinfo);
    },
    applychat() {
      if (this.cardinfo == false) {
        this.$store.state.error.msg =
          "등록된 결제 정보가 없어 결제수단 관리로 이동합니다.";
        this.$store.state.error.errorStatus = true;
        this.$router.push({ path: "/manage/payment" });
      } else {
        this.applytype = "ai";
        this.applymodal = true;
      }
    },
    applyfree() {
      if (this.cardinfo == false) {
        this.$store.state.error.msg =
          "등록된 결제 정보가 없어 결제수단 관리로 이동합니다.";
        this.$store.state.error.errorStatus = true;
        this.$router.push({ path: "/manage/payment" });
      } else {
        this.applytype = "free";
        this.applymodal = true;
      }
    },
    applyservice() {
      if (this.applytype == "ai") {
        const data = {
          type: "ai",
          grade: this.userinfo.grade,
          classNum: this.userinfo.classNum,
        };
        this.servicestatus.aiChatStatus = true;
        this.$store.dispatch("SERVICE_OPEN", data);
        return;
      }
      if (this.applytype == "free") {
        const data = {
          type: "free",
          grade: this.userinfo.grade,
          classNum: this.userinfo.classNum,
        };
        this.servicestatus.freeChatStatus = true;
        this.$store.dispatch("SERVICE_OPEN", data);
        return;
      }
    },
    cancelapply() {
      this.applytype = "";
      this.applymodal = false;
    },
    opendeletemodal(type) {
      this.type = type;
      this.deletemodal = true;
    },
    closedeletemodal() {
      this.deletemodal = false;
      this.type = "";
    },
    deleteservice() {
      if (this.type == "ai") {
        const data = {
          type: this.type,
          grade: this.userinfo.grade,
          classNum: this.userinfo.classNum,
        };

        this.deletemodal = false;
        this.$store.dispatch("DELETE_SERVICE", data);
      } else {
        const data = {
          type: "free",
          grade: this.userinfo.grade,
          classNum: this.userinfo.classNum,
        };

        this.deletemodal = false;
        this.$store.dispatch("DELETE_SERVICE", data);
      }
    },
    billregister() {
      //this.billregistermodal = true;
      this.$router.push({ path: "/manage/payment" });
    },
    cancelbillregister() {
      this.billregistermodal = false;
    },
    month() {
      const date = new Date();
      return date.getMonth() + 1;
    },
    chatapiamount() {
      return { amount: this.aiusage, bill: this.aiusage * 10 };
    },
    freeapiamount() {
      return { amount: this.freeusage, bill: this.freeusage * 20 };
    },
    async setclasslist() {
      const res = await this.$store.dispatch("CLASS_LIST");

      const classList = res.classList;
      const publisherList = res.publisherList;

      let arr = [];
      for (let i = 0; i < classList.length; i++) {
        for (let j = 0; j < classList[i].length; j++) {
          arr.push({
            text: `${i + 3}학년 ${classList[i][j]}반`,
            value: {
              grade: i + 3,
              classNum: classList[i][j],
              publisher: publisherList[i][j],
            },
          });
        }
      }
      this.classlist = arr;
    },
    openchangeinfo() {
      this.changeinfomodal = true;
    },
    cancelchangeinfo() {
      this.changeinfomodal = false;
    },
    changeclass() {
      this.$store.dispatch("SET_CLASS", this.selectinfo);
    },
  },
};
</script>

<style>
</style>