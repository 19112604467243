<template>
  <div>
    <v-row style="margin-top: 150px" justify="center">
      <div style="width: 1000px">
        <p
          style="
            font-size: 27px;
            font-weight: bold;
            font-family: notosans_light;
            margin-left: 10px;
          "
        >
          자유게시판
        </p>
      </div>
    </v-row>
    <v-row justify="center">
      <div style="width: 1000px">
        <p
          style="
            font-size: 16px;
            color: rgb(150, 150, 150);
            font-weight: bold;
            font-family: notosans_light;
            margin-left: 10px;
            margin-top: -12px;
          "
        >
          자유롭게 의견을 남길 수 있는 게시판입니다.
        </p>
      </div>
    </v-row>
    <v-row justify="center">
      <v-card outlined width="1000" height="800">
        <table style="width: 1000px; border-spacing: 0">
          <tr>
            <td id="tabletd" style="width: 90px">1</td>
            <td id="tabletd" style="cursor: pointer">자유게시판 테스트 1</td>
            <td
              id="tabletd"
              style="
                text-align: right;
                font-size: 16px;
                color: rgb(150, 150, 150);
              "
            >
              2023-10-19
            </td>
          </tr>
          <tr>
            <td id="tabletd" style="width: 90px">2</td>
            <td id="tabletd" style="cursor: pointer">자유게시판 테스트 2</td>
            <td
              id="tabletd"
              style="
                text-align: right;
                font-size: 16px;
                color: rgb(150, 150, 150);
              "
            >
              2023-10-19
            </td>
          </tr>
          <tr>
            <td id="tabletd" style="width: 90px">3</td>
            <td id="tabletd" style="cursor: pointer">자유게시판 테스트 3</td>
            <td
              id="tabletd"
              style="
                text-align: right;
                font-size: 16px;
                color: rgb(150, 150, 150);
              "
            >
              2023-10-19
            </td>
          </tr>
        </table>
        <v-row justify="center">
          <div style="position: absolute; bottom: 0" id="page">
            <p>
              <b
                style="
                  font-size: 19px;
                  font-family: notosans_light;
                  font-weight: bold;
                  cursor: pointer;
                "
                >&lt;</b
              ><b
                style="
                  margin-left: 20px;
                  margin-right: 20px;
                  font-size: 19px;
                  font-family: notosans_light;
                  font-weight: bold;
                  cursor: pointer;
                "
                >1</b
              ><b
                style="
                  font-size: 19px;
                  font-family: notosans_light;
                  font-weight: bold;
                  cursor: pointer;
                "
                >></b
              >
            </p>
          </div>
        </v-row>
      </v-card>
    </v-row>
    <v-row style="margin-top: 20px; margin-bottom: 100px" justify="center">
      <div style="width: 1000px">
        <v-btn
          width="150"
          height="50"
          outlined
          style="
            float: right;
            font-size: 18px;
            background-color: #0c7ee5;
            color: white;
            font-family: notosans_light;
            font-weight: bold;
          "
          >글 작성하기</v-btn
        >
      </div>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#tabletd {
  padding: 20px;
  font-family: notosans_light;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid rgb(190, 190, 190);
}
</style>