<template>
  <div>
    <img
      style="width: 100%; margin-top: -60px"
      src="../assets/images/Bg_Main.png"
    />
    <!-- <div style="background-color: rgb(240, 240, 240); margin-top: -10px">
      <v-card
        outlined
        style="margin-top: -100px; margin-left: 100px; float: left"
        width="400"
        height="300"
      >
        <div style="width: 100%; border-bottom: 1px solid black">
          <div
            style="
              font-family: notosans_light;
              font-weight: bold;
              font-size: 19px;
              height: 50px;
              padding: 10px;
            "
          >
            <div style="margin-top: 2px">
              <p style="float: left; margin-left: 2px; margin-top: -1px">
                공지사항
              </p>
              <p
                @click="gonoticehome()"
                style="
                  float: right;
                  font-size: 28px;
                  margin-top: -9px;
                  margin-right: 3px;
                  cursor: pointer;
                "
              >
                +
              </p>
            </div>
          </div>
        </div>
        <div style="width: 100%">
          <table
            style="
              font-family: notosans_light;
              font-weight: bold;
              font-size: 15px;
              border-spacing: 0;
              margin-top: 15px;
              width: 100%;
            "
          >
            <tr v-for="(j, i) in 3" :key="i">
              <td
                @click="gonotice(i + 1)"
                class="noticetd"
                style="cursor: pointer"
              >
                {{ noticeList[i].title }}
              </td>
              <td class="noticetd" style="text-align: right">
                {{ noticeList[i].date }}
              </td>
            </tr>
          </table>
        </div>
      </v-card>
      <v-card
        outlined
        style="margin-top: -100px; margin-left: 50px; float: left"
        width="400"
        height="300"
      >
        <div style="width: 100%; border-bottom: 1px solid black">
          <div
            style="
              font-family: notosans_light;
              font-weight: bold;
              font-size: 19px;
              height: 50px;
              padding: 10px;
            "
          >
            <div style="margin-top: 2px">
              <p style="float: left; margin-left: 2px; margin-top: -1px">
                업데이트
              </p>
              <p
                style="
                  float: right;
                  font-size: 28px;
                  margin-top: -9px;
                  margin-right: 3px;
                  cursor: pointer;
                "
              >
                +
              </p>
            </div>
          </div>
        </div>
        <div style="width: 100%">
          <table
            style="
              font-family: notosans_light;
              font-weight: bold;
              font-size: 15px;
              border-spacing: 0;
              margin-top: 15px;
              width: 100%;
            "
          ></table>
        </div>
      </v-card>
    </div> -->
  </div>
</template>

<script>
import _noticeList from "../assets/notice.json";

export default {
  data() {
    return {
      noticeList: [],
    };
  },
  created() {
    this.noticeList = _noticeList;
  },
  methods: {
    gonotice(index) {
      this.$router.push({ path: `/notice/${index}` });
    },
    gonoticehome() {
      this.$router.push({ path: `/notice` });
    },
  },
};
</script>

<style scoped>
.noticetd {
  padding: 20px;
  padding-top: 0;
}
</style>