<template>
  <div class="wrapHomepage">
    <MainComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import MainComponent from "@/components/MainComponent.vue";

export default {
  name: "HomeView",
  components: {
    MainComponent,
  },
};
</script>

<style>
.wrapHomepage {
  max-width: 3000px;
  min-width: 100%;
  /* border: 1px solid transparent; */
  overflow-x: auto;
}

#back {
  background-image: url("../assets/images/Bg_Main.png");
  /* background-size: 100%;
  background-position: center; */
  max-width: 100%;
  width: 1200px;
  height: auto;
  /* height: 700px; */
}
</style>
