import { instance, instanceAuth, socketinstance } from './url'

const login = async (loginData) => {
  return await instance
    .post('lms/login', loginData, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const userinfo = async () => {
  return await instanceAuth
    .get('/users', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const portalmail = async (data) => {
  return await instanceAuth
    .post('mail/portal/ask', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const cardinfo = async () => {
  return await instanceAuth
    .get('billing/card/info', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const servicestatus = async () => {
  return await instanceAuth
    .get('billing/service/status', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const registercard = async (data) => {
  return await instanceAuth
    .post('billing/card/register', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const deletecard = async () => {
  return await instanceAuth
    .get('billing/card/delete', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const checkusage = async ({ type, grade, classNum }) => {
  return await instanceAuth
    .get(`billing/card/check/${type}/${grade}/${classNum}`, {
      credentials: true
    })
    .catch((err) => {
      return err.response
    })
}

const openservice = async ({ type, grade, classNum }) => {
  return await instanceAuth
    .post(
      `billing/service/open`,
      { type, grade, classNum },
      { credentials: true }
    )
    .catch((err) => {
      return err.response
    })
}

const closeservice = async ({ type, grade, classNum }) => {
  return await instanceAuth
    .post(
      `billing/service/close`,
      { type, grade, classNum },
      { credentials: true }
    )
    .catch((err) => {
      return err.response
    })
}

const classlist = async () => {
  return await instanceAuth
    .get('/lms/classList', { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const changeclass = async (data) => {
  return await instanceAuth
    .post('/lms/set-class', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

const getclasstalkcount = async (data) => {
  return await instanceAuth
    .post('/billing/check/class/usage', data, { credentials: true })
    .catch((err) => {
      return err.response
    })
}

export {
  login,
  userinfo,
  portalmail,
  cardinfo,
  registercard,
  checkusage,
  openservice,
  closeservice,
  deletecard,
  servicestatus,
  classlist,
  changeclass,
  getclasstalkcount
}
