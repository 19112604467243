<template>
  <div>
    <v-row style="margin-top: 200px; margin-bottom: 250px" justify="center">
      <AskComponent />
      <FAQcomponent style="margin-left: 50px" />
    </v-row>
  </div>
</template>

<script>
import AskComponent from "./AskComponent.vue";
import FAQcomponent from "./FAQcomponent.vue";

export default {
  components: {
    FAQcomponent,
    AskComponent,
  },
};
</script>

<style scoped>
#tabletd {
  padding: 20px;
  font-family: notosans_light;
  font-size: 19px;
  font-weight: bold;
  border-bottom: 1px solid rgb(190, 190, 190);
}
</style>