<template>
  <div class="text-center">
    <v-dialog v-model="$store.state.error.errorStatus" width="500" persistent>
      <v-card>
        <header>
          <v-card-title
            style="
              color: white;
              font-size: 22px;
              background-color: #0c7ee5;
              font-family: notosanslight;
              font-weight: bold;
            "
          >
            메시지
          </v-card-title>
        </header>
        <v-card-text>
          <br />
          <h3
            style="
              font-size: 20px;
              font-family: notosanslight;
              font-weight: bold;
              color: black;
            "
            class="error_card_text"
          >
            {{ $store.state.error.msg }}
          </h3>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            style="font-family: notosanslight; font-weight: bold"
            color="black"
            text
            @click="errorCheck"
          >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    async errorCheck() {
      // await this.$store.dispatch("ERROR_RESET", {
      //   result: true,
      //   msg: "",
      //   code: "",
      //   errorStatus: false,
      // });
      this.$store.state.error.errorStatus = false;
      this.$store.state.error.msg = "";
      window.location.reload();
    },
  },
};
</script>

<style>
@font-face {
  font-family: "notosanslight";
  src: url("../assets/fonts/NotoSansCJKkr-Light.otf");
}
</style>