<template>
  <v-app>
    <v-main>
      <Error v-show="$store.state.error.errorStatus === true" />
      <MenuComponentVue />
      <router-view></router-view>
      <v-footer
        style="
          position: ;
          bottom: 0;
          width: 100%;
          height: 250px;
          background-color: #f0f8fe;
          margin-top: 30px;
        "
      >
        <v-row justify="center">
          <div
            style="
              text-align: center;
              font-family: notosans_light;
              font-weight: bold;
              font-size: 14px;
              padding: 20px;
              width: 100%;
            "
          >
            <v-row style="margin-top: 10px" justify="center">
              <p
                id="gain"
                @click="gogain()"
                style="cursor: pointer; font-size: 18px"
              >
                개인정보처리방침
              </p>
              <p
                id="yak"
                @click="goyak()"
                style="cursor: pointer; margin-left: 20px; font-size: 18px"
              >
                이용약관
              </p>
            </v-row>
            <div style="margin-top: 10px">
              사업자 등록번호: 108-86-05434, 통신판매업신고번호:
              2023-서울금천-제0177 호
            </div>

            <div style="margin-top: 15px">
              <p>
                이메일: support@dnsoft.co.kr, 연락처: 02-837-8313, 팩스:
                02-6280-8326, 주소: 서울시 금천구 디지털로 9길 46 이앤씨드림타워
                7차 503호
              </p>
            </div>

            <div>
              <p style="margin-top: 18px; font-size: 17px">
                © 2023 DNSOFT co. ltd. All rights reserved
              </p>
            </div>
          </div>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import MenuComponentVue from "./components/MenuComponent.vue";
import Error from "./components/ErrorModal.vue";
export default {
  components: {
    MenuComponentVue,
    Error,
  },
  methods: {
    gogain() {
      window.open(
        "https://argame3.blob.core.windows.net/policy/argong3PrivacyPolicy.html"
      );
    },
    goyak() {
      window.open(
        "https://argame3.blob.core.windows.net/policy/%EC%95%8C%EA%B3%B5%20%EC%84%9C%EB%B9%84%EC%8A%A4%EC%9D%B4%EC%9A%A9%EC%95%BD%EA%B4%80_v1.html"
      );
    },
  },
};
</script>

<style lang="scss">
#app {
  min-width: 1110px;
}
@font-face {
  font-family: notosans_bold;
  src: url("./assets/fonts/NotoSansCJKkr-Bold.otf");
}

@font-face {
  font-family: notosans_light;
  src: url("./assets/fonts/NotoSansCJKkr-Light.otf");
}

@font-face {
  font-family: notosans_medium;
  src: url("./assets/fonts/NotoSansCJKkr-Medium.otf");
}

@font-face {
  font-family: notosans_regular;
  src: url("./assets/fonts/NotoSansCJKkr-Regular.otf");
}

@font-face {
  font-family: notosans_thin;
  src: url("./assets/fonts/NotoSansCJKkr-Thin.otf");
}

#gain:hover {
  text-decoration: underline;
}

#yak:hover {
  text-decoration: underline;
}
</style>
