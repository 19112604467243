import {
  cardinfo,
  changeclass,
  checkusage,
  classlist,
  closeservice,
  deletecard,
  getclasstalkcount,
  login,
  openservice,
  portalmail,
  registercard,
  servicestatus,
  userinfo
} from '../api/api-list'

const Login = async ({ commit }, data) => {
  const res = await login(data)
  await commit('SET_LOGIN', res.data.data.token)
}

const USER_INFO = async ({ commit }, data) => {
  const res = await userinfo()
  return res.data.data
}

const SEND_MAIL = async ({ commit }, data) => {
  await portalmail(data)
}

const CARD_INFO = async ({ commit }) => {
  const res = await cardinfo()
  return res.data.data
}

const CARD_REGISTER = async ({ commit }, data) => {
  const res = await registercard(data)
  if (res.data.data != false) {
    window.location.reload()
  } else {
    await commit('ERROR', '카드 정보를 확인해주세요.')
  }
}

const SERVICE_STATUS = async ({ commit }) => {
  const res = await servicestatus()
  return res.data.data
}

const SERVICE_OPEN = async ({ commit }, data) => {
  console.log(data)
  const res = await openservice(data)
  if (res.data.data != false) {
    window.location.reload()
  } else {
    await commit('ERROR', '오류가 발생하였습니다.')
  }
}

const SERVICE_CLOSE = async ({ commit }, data) => {
  await closeservice(data)
}

const SERVICE_USAGE = async ({ commit }, data) => {
  const res = await checkusage(data)
  return res.data.data
}

const CLASS_LIST = async ({ commit }) => {
  const res = await classlist()
  return res.data.data
}

const SET_CLASS = async ({ commit }, data) => {
  const res = await changeclass(data)
  if (res.data.result) {
    window.location.reload()
  }
}

const DELETE_SERVICE = async ({ commit }, data) => {
  const res = await closeservice(data)
  if (res.data.data != false) {
    await commit('ERROR', '서비스가 해지되었습니다.')
  } else {
    await commit('ERROR', '결제 오류가 발생하였습니다.')
  }
}

const GET_COUNT = async ({ commit }, data) => {
  const res = await getclasstalkcount(data)
  return res.data.data
}

const DELETE_CARD = async ({ commit }, data) => {
  await deletecard()
  return
}

export {
  Login,
  USER_INFO,
  SEND_MAIL,
  CARD_REGISTER,
  CARD_INFO,
  SERVICE_STATUS,
  SERVICE_OPEN,
  SERVICE_CLOSE,
  SERVICE_USAGE,
  CLASS_LIST,
  SET_CLASS,
  DELETE_SERVICE,
  GET_COUNT,
  DELETE_CARD
}
