<template>
  <!-- <div>{{ noticeData }}</div> -->
  <v-row justify="center" style="margin-top: 200px; margin-bottom: 100px">
    <v-card outlined width="700" height="800">
      <!-- <div>{{ noticeData }}</div> -->
      <div
        style="
          font-family: notosans_light;
          font-size: 25px;
          font-weight: bold;
          padding: 20px;
          width: 100%;
          border-bottom: 1px solid rgb(150, 150, 150);
        "
      >
        {{ noticeData.title }}
      </div>
      <div
        style="
          float: right;
          font-family: notosans_light;
          font-size: 17px;
          font-weight: bold;
          margin-right: 15px;
          margin-top: 10px;
        "
      >
        {{ noticeData.writer }} | 작성일: {{ noticeData.date }}
      </div>
      <v-row style="margin-top: 100px" justify="center">
        <div
          style="
            width: 80%;
            font-family: notosans_light;
            font-size: 17px;
            font-weight: bold;
          "
        >
          <p v-html="noticeData.content_html"></p>
        </div>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import _noticeList from "../assets/notice.json";

export default {
  data() {
    return {
      noticeData: {},
    };
  },
  created() {
    const index = this.$route.params.index;
    const noticeList = _noticeList;
    this.noticeData = noticeList[index - 1];
  },
};
</script>

<style>
</style>