import { render, staticRenderFns } from "./SupportComponent.vue?vue&type=template&id=bb52293e&scoped=true"
import script from "./SupportComponent.vue?vue&type=script&lang=js"
export * from "./SupportComponent.vue?vue&type=script&lang=js"
import style0 from "./SupportComponent.vue?vue&type=style&index=0&id=bb52293e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb52293e",
  null
  
)

export default component.exports