import Vue from 'vue'
import Vuex from 'vuex'
import * as mutations from './mutation'
import * as actions from './action'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: {
      msg: '',
      code: '',
      result: true,
      errorStatus: false
    },
    token: sessionStorage.getItem('token') || '',
    username: sessionStorage.getItem('username')
  },
  getters: {
    logCheck(state) {
      return state.token !== ''
    }
  },
  modules: {},
  actions,
  mutations
})
