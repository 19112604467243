<template>
  <div>
    <v-row style="margin-top: 150px" justify="center">
      <div style="width: 700px">
        <p
          style="
            font-size: 27px;
            font-weight: bold;
            font-family: notosans_light;
            margin-left: 10px;
          "
        >
          결제 수단 관리
        </p>
      </div>
    </v-row>
    <v-row justify="center">
      <div style="width: 700px">
        <p
          style="
            font-size: 16px;
            color: rgb(150, 150, 150);
            font-weight: bold;
            font-family: notosans_light;
            margin-left: 10px;
            margin-top: -12px;
          "
        >
          결제 수단 등록을 통해 알공 부가서비스들을 사용하실 수 있습니다.
        </p>
      </div>
    </v-row>
    <v-row justify="center" style="margin-bottom: 110px">
      <v-card outlined width="700" height="700">
        <div v-if="cardinfo == false">
          <v-row justify="center" style="margin-top: 60px">
            <div
              style="
                width: 280px;
                height: 150px;
                background-color: rgb(220, 220, 220);
              "
            >
              <v-row style="margin-top: 23px" justify="center">
                <img
                  @click="billregister()"
                  width="100"
                  src="../assets/images/cardplus.png"
                  style="cursor: pointer"
                  alt=""
                />
              </v-row>
            </div>
          </v-row>
          <v-row style="margin-top: 50px" justify="center">
            <v-btn
              @click="billregister()"
              width="190"
              height="50"
              style="
                font-weight: bold;
                background-color: #0c7ee5;
                color: white;
                font-size: 19px;
                font-family: notosans_light;
              "
              outlined
              >결제 수단 등록</v-btn
            >
          </v-row>
        </div>
        <div v-if="cardinfo != false">
          <v-row justify="center" style="margin-top: 10px">
            <img width="300" src="../assets/images/creditcard.png" alt="" />
          </v-row>
          <v-row justify="center">
            <p
              style="
                font-size: 20px;
                font-family: notosans_light;
                font-weight: bold;
                margin-top: -30px;
              "
            >
              {{ cardChk(cardinfo) }}
            </p>
          </v-row>
          <v-row style="margin-top: 50px" justify="center">
            <v-btn
              @click="deletemodal()"
              width="190"
              height="50"
              style="
                font-weight: bold;
                background-color: #fc5230;
                color: white;
                font-size: 19px;
                font-family: notosans_light;
              "
              outlined
              >결제 수단 해지</v-btn
            >
          </v-row>
        </div>
        <!-- <v-row justify="center">
          <img width="300" src="../assets/images/creditcard.png" alt="" />
        </v-row> -->
      </v-card>
    </v-row>
    <v-dialog persistent v-model="billregistermodal" width="650" height="900">
      <v-card
        style="font-family: notosans_light; font-weight: bold; overflow: hidden"
        width="650"
        height="860"
      >
        <header>
          <v-card-title
            style="background-color: #0c7ee5; color: white; font-weight: bold"
          >
            결제 수단 등록
          </v-card-title>
        </header>
        <div style="width: 100%">
          <v-row style="margin-top: 30px" justify="center">
            <div style="width: 500px">
              <p style="font-size: 18px">카드 소유자명</p>
              <div style="width: 500px">
                <v-text-field
                  v-model="name"
                  style="margin-top: -5px"
                  outlined
                  placeholder="카드 소유자명을 입력해주세요."
                ></v-text-field>
              </div>
            </div>
          </v-row>
          <v-row style="margin-top: -10px" justify="center">
            <div style="width: 500px">
              <p style="font-size: 18px">전화번호</p>
              <div style="width: 500px">
                <v-text-field
                  v-model="phonenum"
                  style="margin-top: -5px"
                  outlined
                  placeholder="전화번호를 입력해주세요."
                ></v-text-field>
              </div>
            </div>
          </v-row>
          <v-row style="margin-top: -10px" justify="center">
            <div style="width: 500px">
              <p style="font-size: 18px">카드 번호</p>
              <div style="width: 115px; float: left">
                <v-text-field
                  v-model="cardnum1"
                  style="margin-top: -5px"
                  outlined
                ></v-text-field>
              </div>
              <div style="width: 115px; float: left; margin-left: 10px">
                <v-text-field
                  v-model="cardnum2"
                  style="margin-top: -5px"
                  outlined
                ></v-text-field>
              </div>
              <div style="width: 115px; float: left; margin-left: 10px">
                <v-text-field
                  type="password"
                  v-model="cardnum3"
                  style="margin-top: -5px"
                  outlined
                ></v-text-field>
              </div>
              <div style="width: 115px; float: left; margin-left: 10px">
                <v-text-field
                  type="password"
                  v-model="cardnum4"
                  style="margin-top: -5px"
                  outlined
                ></v-text-field>
              </div>
            </div>
          </v-row>
          <v-row style="margin-top: -10px" justify="center">
            <div style="width: 500px">
              <p style="font-size: 18px">유효 기간</p>
              <div style="width: 110px; float: left">
                <v-text-field
                  v-model="expmonth"
                  style="margin-top: -5px"
                  outlined
                  placeholder="MM"
                ></v-text-field>
              </div>
              <div style="width: 110px; float: left; margin-left: 10px">
                <v-text-field
                  v-model="expyear"
                  style="margin-top: -5px"
                  outlined
                  placeholder="YY"
                ></v-text-field>
              </div>
            </div>
          </v-row>
          <v-row style="margin-top: -10px" justify="center">
            <div style="width: 500px">
              <p style="font-size: 18px">본인확인</p>
              <div style="width: 500px">
                <v-text-field
                  v-model="cardhost"
                  style="margin-top: -5px"
                  outlined
                  placeholder="개인카드 : 생년월일 6자리/ 법인카드: 사업자 번호"
                ></v-text-field>
              </div>
            </div>
          </v-row>
          <v-row style="margin-top: -10px" justify="center">
            <div style="width: 500px">
              <p style="font-size: 18px">비밀번호</p>
              <div style="width: 150px; float: left">
                <v-text-field
                  v-model="password"
                  type="password"
                  style="margin-top: -5px"
                  outlined
                  placeholder="비밀번호 앞 두자리"
                ></v-text-field>
              </div>
              <div style="float: left; margin-top: 8px; margin-left: 15px">
                <p style="font-size: 30px">**</p>
              </div>
            </div>
          </v-row>

          <v-row style="margin-top: -10px" justify="center">
            <v-btn
              @click="cardregister()"
              width="140"
              height="50"
              outlined
              style="
                margin-top: 30px;
                font-size: 18px;
                font-weight: bold;
                background-color: #0c7ee5;
                color: white;
              "
              >카드 등록</v-btn
            >
            <v-btn
              @click="cancelbillregister()"
              width="140"
              height="50"
              outlined
              style="
                margin-top: 30px;
                font-size: 18px;
                font-weight: bold;
                background-color: #fc5230;
                color: white;
                margin-left: 20px;
              "
              >취소</v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog persistent width="700" height="550" v-model="deletecardmodal">
      <v-card width="700" height="550" outlined style="overflow: hidden">
        <div
          style="padding: 30px; font-family: notosans_light; font-weight: bold"
        >
          <p style="font-size: 30px">카드 해지</p>
        </div>
        <div
          style="padding: 35px; font-family: notosans_light; font-weight: bold"
        >
          <p style="font-size: 19px">[카드 해지시 안내]</p>
          <p style="font-size: 18px">
            1. 카드 정보 삭제시 모든 서비스가 즉시 이용 중지됩니다.
          </p>
          <p style="font-size: 18px">
            2. 신청하신 학년/반 기준으로 API 사용량이 측정되며, 각 학년/반 별로
            API 사용량 만큼 자동으로 결제되고, 카드 정보가 삭제됩니다.
          </p>
        </div>
        <v-row justify="center">
          <div>
            <p
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 25px;
                margin-right: 10px;
              "
            >
              카드 정보를 삭제하시겠습니까?
            </p>
          </div>
        </v-row>
        <v-row style="margin-top: 30px" justify="center">
          <div style="margin-right: 10px">
            <v-btn
              @click="deletecard()"
              width="130"
              height="50"
              outlined
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 18px;
                background-color: #0c7ee5;
                color: white;
              "
              >확인</v-btn
            >
            <v-btn
              @click="canceldelete()"
              width="130"
              height="50"
              outlined
              style="
                font-family: notosans_light;
                font-weight: bold;
                font-size: 18px;
                margin-left: 40px;
                background-color: #fc5230;
                color: white;
              "
              >취소</v-btn
            >
          </div>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cardinfo: {},
      billregistermodal: false,
      cardnum1: "",
      cardnum2: "",
      cardnum3: "",
      cardnum4: "",
      cardhost: "",
      phonenum: "",
      expmonth: "",
      expyear: "",
      password: "",
      name: "",
      deletecardmodal: false,
    };
  },
  async created() {
    this.cardinfo = await this.$store.dispatch("CARD_INFO");
    console.log(this.cardinfo);
  },
  methods: {
    billregister() {
      this.billregistermodal = true;
    },
    cancelbillregister() {
      this.billregistermodal = false;
    },
    cardChk(cardinfo) {
      const ccnumber = cardinfo.cardNum.split("-").join("");

      var card1 = String(ccnumber.slice(0, 4));
      var mask_value = String(ccnumber.slice(4, 12));
      var card2 = String(ccnumber.slice(12, 16));
      var mask = "";

      for (var i = 0; i < mask_value.length; i++) {
        mask += "*";
      }

      return (
        String(card1) +
        String(mask) +
        String(card2) +
        " " +
        `(${cardinfo.name})`
      );
    },
    cardregister() {
      const data = {
        name: this.name,
        phoneNum: this.phonenum,
        cardNum: `${this.cardnum1}${this.cardnum2}${this.cardnum3}${this.cardnum4}`,
        cardExpiredate: `${this.expmonth}/${this.expyear}`,
        cardHost: this.cardhost,
        password: `${this.password}00`,
      };

      this.$store.dispatch("CARD_REGISTER", data);
    },
    deletemodal() {
      this.deletecardmodal = true;
    },
    deletecard() {
      this.$store.dispatch("DELETE_CARD");
      this.$store.state.error.msg = "카드 정보가 삭제되었습니다.";
      this.$store.state.error.errorStatus = true;
    },
    canceldelete() {
      this.deletecardmodal = false;
    },
  },
};
</script>

<style>
</style>