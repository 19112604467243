<template>
  <div>
    <v-row justify="center">
      <div style="width: 600px">
        <p
          style="
            font-size: 27px;
            font-weight: bold;
            font-family: notosans_light;
            margin-left: 10px;
          "
        >
          문의하기
        </p>
      </div>
    </v-row>
    <v-row justify="center">
      <div style="width: 600px">
        <p
          style="
            font-size: 16px;
            color: rgb(150, 150, 150);
            font-weight: bold;
            font-family: notosans_light;
            margin-left: 10px;
            margin-top: -12px;
          "
        >
          이메일 주소와 함께 문의를 주시면 해당 이메일로 답변 메일이 발송됩니다.
        </p>
      </div>
    </v-row>
    <v-row justify="center">
      <v-card outlined width="600" height="500">
        <v-row style="margin-top: 30px" justify="center">
          <div style="width: 500px">
            <div style="width: 300px">
              <input
                v-model="email"
                spellcheck="false"
                style="
                  width: 300px;
                  font-weight: bold;
                  font-family: notosans_light;
                  border: 2px solid rgb(200, 200, 200);
                  padding: 15px;
                  border-radius: 5px;
                "
                placeholder="이메일 주소를 입력해주세요"
              />
            </div>
          </div>
        </v-row>
        <v-row style="margin-top: 30px" justify="center">
          <div>
            <textarea
              v-model="email_content"
              spellcheck="false"
              placeholder="문의 사항을 입력해주세요"
              style="
                width: 500px;
                resize: none;
                border: 2px solid rgb(200, 200, 200);
                padding: 15px;
                font-size: 17px;
                font-weight: bold;
                font-family: notosans_light;
                border-radius: 5px;
              "
              rows="10"
            ></textarea>
          </div>
        </v-row>
        <v-row style="margin-top: 20px" justify="center">
          <div style="width: 500px">
            <v-btn
              @click="sendemail()"
              outlined
              width="130"
              height="50"
              style="
                font-weight: bold;
                background-color: #0c7ee5;
                color: white;
                font-size: 18px;
                float: right;
                border-radius: 5px;
                font-family: notosans_light;
              "
              >문의하기</v-btn
            >
          </div>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      email_content: "",
    };
  },
  methods: {
    sendemail() {
      if (this.email == "" || this.email_content == "") {
        return;
      } else {
        this.$store.dispatch("SEND_MAIL", {
          email: this.email,
          content: this.email_content,
        });
        this.email = "";
        this.email_content = "";
        this.$store.state.error.msg = "문의가 정상적으로 발송되었습니다.";
        this.$store.state.error.errorStatus = true;
      }
    },
  },
};
</script>

<style scoped>
#tabletd {
  padding: 20px;
  font-family: notosans_light;
  font-size: 19px;
  font-weight: bold;
  border-bottom: 1px solid rgb(190, 190, 190);
}
</style>