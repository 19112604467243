<template>
  <div>
    <v-row style="margin-top: 300px" justify="center">
      <div>
        <!-- <p
          style="
            font-family: notosans_light;
            font-weight: bold;
            font-size: 35px;
          "
        >
          Login
        </p> -->
        <div
          style="
            background-color: rgb(90, 90, 90);
            width: 700px;
            height: 150px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          "
        >
          <v-row justify="center">
            <img
              style="margin-top: 40px"
              src="../assets/images/Argong_Logo.png"
              width="200"
              alt=""
            />
          </v-row>
        </div>
      </div>
    </v-row>
    <v-row style="margin-bottom: 300px" justify="center">
      <v-card width="700" height="400" outlined>
        <v-row style="margin-top: 100px" justify="center">
          <div style="width: 400px">
            <v-text-field
              @keyup.enter="login()"
              v-model="id"
              style="font-family: notosans_light; font-weight: bold"
              placeholder="아이디를 입력해주세요"
              outlined
            ></v-text-field>
          </div>
        </v-row>
        <v-row justify="center">
          <div style="width: 400px">
            <v-text-field
              @keyup.enter="login()"
              type="password"
              v-model="password"
              style="font-family: notosans_light; font-weight: bold"
              placeholder="비밀번호를 입력해주세요"
              outlined
            ></v-text-field>
          </div>
        </v-row>
        <v-row justify="center">
          <v-btn
            @keyup.enter="login()"
            @click="login()"
            outlined
            width="400"
            height="50"
            style="
              font-size: 20px;
              font-family: notosans_light;
              font-weight: bold;
              background-color: #0c7ee5;
              color: white;
            "
            >로그인</v-btn
          >
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      password: "",
    };
  },
  created() {
    if (this.$store.state.token) {
      this.$router.replace({ path: "/service" });
    }
  },
  methods: {
    async login() {
      if (this.id == "" || this.password == "") {
        return;
      }

      this.$store.dispatch("Login", {
        id: this.id,
        password: this.password,
        os: "pc",
      });
    },
  },
};
</script>

<style>
</style>