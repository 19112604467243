<template>
  <div>
    <v-row style="margin-top: 150px" justify="center">
      <div style="width: 1000px">
        <p
          style="
            font-size: 27px;
            font-weight: bold;
            font-family: notosans_light;
            margin-left: 10px;
          "
        >
          공지사항
        </p>
      </div>
    </v-row>
    <v-row justify="center">
      <div style="width: 1000px">
        <p
          style="
            font-size: 16px;
            color: rgb(150, 150, 150);
            font-weight: bold;
            font-family: notosans_light;
            margin-left: 10px;
            margin-top: -12px;
          "
        >
          알공의 최신 소식을 받아보실 수 있습니다.
        </p>
      </div>
    </v-row>
    <v-row style="margin-bottom: 50px" justify="center">
      <v-card outlined width="1000" height="800">
        <table style="width: 1000px; border-spacing: 0">
          <tr v-for="(j, i) in noticeList" :key="i">
            <td id="tabletd" style="width: 90px">
              {{ i + 1 }}
            </td>
            <td
              id="tabletd"
              style="cursor: pointer"
              @click="gonoticeindex(i + 1)"
            >
              {{ noticeList[i].title }}
            </td>
            <td
              id="tabletd"
              style="
                text-align: right;
                font-size: 16px;
                color: rgb(150, 150, 150);
              "
            >
              {{ noticeList[i].date }}
            </td>
          </tr>
        </table>
        <v-row justify="center">
          <div style="position: absolute; bottom: 0" id="page">
            <p>
              <b
                style="
                  font-size: 19px;
                  font-family: notosans_light;
                  font-weight: bold;
                  cursor: pointer;
                "
                >&lt;</b
              ><b
                style="
                  margin-left: 20px;
                  margin-right: 20px;
                  font-size: 19px;
                  font-family: notosans_light;
                  font-weight: bold;
                  cursor: pointer;
                "
                >1</b
              ><b
                style="
                  font-size: 19px;
                  font-family: notosans_light;
                  font-weight: bold;
                  cursor: pointer;
                "
                >></b
              >
            </p>
          </div>
        </v-row>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import _noticeList from "../assets/notice.json";

export default {
  created() {
    this.noticeList = _noticeList;
  },
  data() {
    return {
      noticeList: [],
    };
  },
  methods: {
    gonoticeindex(index) {
      this.$router.push({ path: `/notice/${index}` });
    },
  },
};
</script>

<style scoped>
#tabletd {
  padding: 20px;
  font-family: notosans_light;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid rgb(190, 190, 190);
}
</style>